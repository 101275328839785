import * as React from "react"
import { useState } from "react"
import PageWithoutHero from "../components/PageWithoutHero"
import { auth } from "../utils/firebase"
import { GoogleAuthProvider, signOut } from "firebase/auth"
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth"
import "firebase/compat/auth"
import Link from "../components/Link"
import { useAuthState } from "react-firebase-hooks/auth"


const uiConfig = {
  // Popup signin flow rather than redirect flow.
  signInFlow: "popup",
  // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
  signInSuccessUrl: "/admin/build-selector/create",
  // We will display Google and Facebook as auth providers.
  signInOptions: [
    GoogleAuthProvider.PROVIDER_ID
    // FacebookAuthProvider.PROVIDER_ID,
  ]
}

const Login = (props) => {
  const [code, setCode] = useState("")
  const [user, loading, error] = useAuthState(auth)

  return (
    <PageWithoutHero>
      <h2>Login</h2>
      {!loading && user &&
        <>
          <div>
            {auth.currentUser.displayName} - {auth.currentUser.email} - <a href={"#"} onClick={(e) => {
            e.preventDefault()
            return signOut(auth)
          }}>Logout</a>
          </div>
          <hr/>
          <Link to={"/admin/build-selector/create/"}>Create New Build</Link><br/>
          <div style={{ marginTop: 32 }}>
            <input type={"text"} placeholder={"Code"} onChange={(e) => setCode(e.target.value)}/>
            <div>
              <Link target={"_blank"} to={`/admin/build-selector/${code}/pre-selections/`}>Edit {code} Pre-selections</Link><br/>
            </div>
            <div>
              <Link target={"_blank"} to={`/accounts/${code}/build-selections/`}>Edit {code} Selections</Link><br/>
            </div>
          </div>
        </>
      }

      {!loading && !user &&
        <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={auth}/>
      }

    </PageWithoutHero>
  )
}

export default Login
